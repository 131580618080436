import $ from 'jquery'

import select2 from 'select2'
import 'select2/dist/css/select2.css'

select2(window,$);

window.addEventListener('DOMContentLoaded', () => {
  var inviteTable = $('#user-table-id').DataTable({
    searching: false,
    paging: false,
    info: false,
    order: [[3, 'desc']]
  });
});
